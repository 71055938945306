import firebase from 'firebase';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDy7dLRt5IrPR1zU6L7ZXGgE30xIGmk1oA',
  authDomain: 'arkana-n.firebaseapp.com',
  projectId: 'arkana-n',
  storageBucket: 'arkana-n.appspot.com',
  messagingSenderId: '748789591930',
  appId: '1:748789591930:web:9ef4f02046a5d85751b0c3',
  measurementId: 'G-HCETERE5D5',
};

export default firebase.initializeApp(firebaseConfig);
