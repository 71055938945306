import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import SectionSubtitle from '../components/SectionSubtitle';
import SectionTitle from '../components/SectionTitle';
import switchToEnglish from '../hoc/switchToEnglish';

const sectionTitleText = {
  en: {
    title: 'Contact',
    subtitle: 'Contact with us',
  },
  pl: {
    title: 'Kontakt',
    subtitle: 'Skontaktuj się z nami',
  },
};

const contactCardText = {
  pl: {
    subtitle: 'DANE KONTAKTOWE',
    description: 'Serdecznie zapraszamy do kontaktu.',
    extraInfo: 'Aby uzyskać informacje kontaktowe dotyczące Twojego budynku - wyszukaj go w sekcji Twoja nieruchomość.',
    workingHours: 'godziny/dni przyjmowania stron: ',
    hours: 'pon. - czw., 8.00 - 16.00',
  },
  en: {
    subtitle: 'CONTACT INFO',
    description: 'Serdecznie zapraszamy do konatku.',
    extraInfo: 'Aby uzyskać informacje kontaktowe dotyczące Twojego budynku - wyszukaj go w sekcji Twoja nieruchomość',
    workingHours: 'working hours: ',
    hours: 'Mon. - Fri., 8.00 - 16.00',
  },

};

const Contact = ({ en }) => (
  <section id="contact" className="contact">
    <div className="contact__space" />
    <SectionTitle
      title={switchToEnglish(en, sectionTitleText).title}
      subtitle={switchToEnglish(en, sectionTitleText).subtitle}
    />
    <Grid>
      <Row around="xs">
        <Col xs={10} sm={10} md={10} lg={4} xl={6} className="text-card">
          <div className="contact__order">
            <SectionSubtitle
              subtitle={switchToEnglish(en, contactCardText).subtitle}
            />
            <p>{switchToEnglish(en, contactCardText).description}</p>
            <p>{switchToEnglish(en, contactCardText).extraInfo}</p>
            <strong>Arkana Nieruchomości Sp. z o.o.</strong>
            {' '}
            <br />
            ul. Chodkiewicza 6/15
            {' '}
            <br />
            {' '}
            31-532 Kraków
            {' '}
            <br />
            NIP: 6751510532
            {' '}
            <br />
            {' '}
            KRS : 0000530269
            {' '}
            <br />

            {switchToEnglish(en, contactCardText).workingHours}
            <span className="bold">pon. - czw., 8.00 - 16.00</span>
            <br />
            {' '}
            tel:
            {' '}
            <span className="bold">
              <a href="tel: + 48 501 715 111">+48 501 715 111</a>

            </span>
            <br />
            tel:
            {' '}
            <span className="bold"><a href="tel: + 48 12 430 10 03">+48 12 430 10 03</a></span>
            <br />
            email:
            {' '}
            <span className="bold"><a href="mailto:biuro@arkana.com.pl">biuro@arkana.com.pl</a></span>
          </div>
        </Col>
      </Row>
    </Grid>
  </section>
);

export default Contact;
