import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { Message } from 'semantic-ui-react';
import styled from 'styled-components';
import logo from '../../assets/img/logo.svg';
import SectionTitle from '../SectionTitle';

const Login = ({
  username,
  setUsername,
  setPass,
  logIn,
  pass,
  err,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      logIn();
    }
  };
  return (
    <Grid>
      <section id="contact" className="contact">
        <Wrapper>
          <img
            src={logo}
            height="48px"
            width="48px"
            alt="log"
          />
        </Wrapper>
        <Wrapper>
          <Container>
            <SectionTitle
              title="Panel administratora"
              subtitle="wpisz dane aby się zalogować"
            />
            <Row center="center">
              <Col
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div className="ui input">
                  <input
                    type="text"
                    placeholder="Użytkownik"
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 24,
                }}
              >
                <div className="ui input">
                  <input
                    type="password"
                    placeholder="Hasło"
                    value={pass}
                    onChange={(event) => setPass(event.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </Col>
            </Row>
            <Row center="xs">
              <Col
                xs={12}
                style={{
                  marginTop: 24,
                  marginBottom: 42,
                }}
              >
                <button className="button__dark--left" type="button" onClick={logIn}>
                  zaloguj się
                </button>
              </Col>
            </Row>
            {err
              ? (
                <Message negative>
                  <Message.Header>Błedne dane</Message.Header>
                  <p>Spróbuj ponownie</p>
                </Message>
              ) : null}
          </Container>
        </Wrapper>
      </section>
    </Grid>
  );
};

export default Login;

export const Container = styled.div`
  width: 524px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;
