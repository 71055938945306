import React, { useEffect, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import Login from '../components/Admin/Login';
import Panel from '../components/Admin/Panel';
import _firebase from '../_firebase';

const Admin = () => {
  const [pass, setPass] = useState('');
  const [username, setUsername] = useState('');
  const [err, setErr] = useState(false);
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(false);

  const logIn = async () => {
    try {
      if (username && pass) {
        setLoading(true);
        await _firebase.auth().signInWithEmailAndPassword(username,
          pass);
        setLoading(false);
        setErr(false);
        setUsername('');
        setPass('');
      }
    } catch (error) {
      setLoading(false);
      setUsername('');
      setPass('');
      setErr(true);
      setTimeout(() => {
        setErr(false);
      }, 5000);
    }
  };

  useEffect(() => {
    const unsubscribe = _firebase.auth().onAuthStateChanged(async (usr) => {
      if (usr && usr.email === 'biuro@arkana.com.pl') {
        setUser(true);
      } else {
        setUser(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  if (user) {
    return (
      <Panel />
    );
  }

  return (
    <>
      <Dimmer active={loading}>
        <Loader />
      </Dimmer>
      <Login
        {...{
          logIn,
          pass,
          setPass,
          username,
          setUsername,
          err,
        }}
      />
    </>
  );
};

export default Admin;
