/* eslint-disable no-plusplus */
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Col, Row } from 'react-flexbox-grid';

const Slogan = () => (
  <>
    <Row
      className="slogan-container"
      id="top"
    >
      <Col xs={12} sm={6}>
        <Row end="sm">
          <div className="header-container">
            <h1>
              ARKANA NIERUCHOMOSCI SP. Z O. O.
            </h1>
            <div className="site-tile__text">
              <strong>
                Trzy dekady
                <br />
                doświadczenia
              </strong>
            </div>
            <Row style={{
              marginTop: 24,
            }}
            >
              <AnchorLink href="#aboutUs" className="noStyle">
                <button className="site-title__button" type="button">
                  Dowiedz się więcej
                </button>
              </AnchorLink>
            </Row>
          </div>
        </Row>
        <Row end="xs" />

      </Col>
    </Row>
  </>
);

export default Slogan;
