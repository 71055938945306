import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import SectionTitle from '../components/SectionTitle';
import TimlineCardMobile from '../components/Timeline/TimelineCardMobile';
import switchToEnglish from '../hoc/switchToEnglish';
import {
  card1Text, card2Text, card3Text, card4Text, sectionTitleText,
} from './Timeline';

const Line = () => (
  <Row around="xl">
    <Col xs={12} className="timeline__lineHeight" />
  </Row>
);

const TimelineMobile = ({ en }) => (
  <section className="timeline" id="exp">
    <SectionTitle
      title={switchToEnglish(en, sectionTitleText).title}
      subtitle={switchToEnglish(en, sectionTitleText).subtitle}
    />
    <div className="timeline__mainLine" />
    <Grid>
      <TimlineCardMobile
        date="1995"
        description={switchToEnglish(en, card1Text).description}
      />
      <Line />
      <TimlineCardMobile
        date="2000"
        description={switchToEnglish(en, card2Text).description}
      />
      <Line />
      <TimlineCardMobile
        date="2012"
        description={switchToEnglish(en, card3Text).description}
      />
      <Line />
      <TimlineCardMobile
        date="2013"
        description={switchToEnglish(en, card4Text).description}
      />
    </Grid>
  </section>
);

export default TimelineMobile;
