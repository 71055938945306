/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon } from 'semantic-ui-react';
import { LINK_FACEBOOK, LINK_INSTAGRAM } from '../../data/links';
import { selectEnglish } from '../../store/actions/index';

class NavListSecondary extends Component {
  switchLanguage = (e) => {
    const { english } = this.props;
    e.preventDefault();
    english();
  }

  render() {
    return (
      <ul className="nav__list--secondary">
        <a href={LINK_INSTAGRAM} target="_blank" rel="noopener noreferrer"><li className="nav__list-item"><Icon name="instagram" /></li></a>
        <li className="nav__list-item--border" />
        <a href={LINK_FACEBOOK} target="_blank" rel="noopener noreferrer"><li className="nav__list-item"><Icon name="facebook" /></li></a>
      </ul>
    );
  }
}

const mapStateToProps = (state) => ({
  en: state.english,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ english: selectEnglish }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NavListSecondary);
