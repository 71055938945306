import React from 'react';
import { BrowserRouter as AppRouter, Route, Switch } from 'react-router-dom';
import Admin from '../screens/Admin';
import Home from '../screens/Home';

const Router = () => (
  <>
    <AppRouter>
      <Switch>
        <Route
          path="/"
          exact
          render={() => (
            <Home />
          )}
        />
        <Route
          path="/admin"
          exact
          render={() => (
            <Admin />
          )}
        />
      </Switch>
    </AppRouter>
  </>
);

export default Router;
