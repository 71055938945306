import * as actionTypes from '../actions/actionTypes';

const initialState = {
  building: {},
  english: false,
  polish: true,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SELECT_BUILDING:
      return { ...state, building: action.payload };
    case actionTypes.UNSELECT_BUILDING:
      return { ...state, building: {} };
    case actionTypes.SELECT_ENGLISH:
      return { ...state, english: !state.english, polish: false };
    case actionTypes.SELECT_POLISH:
      return { ...state, english: false, polish: true };
    default:
      return state;
  }
}

export default reducer;
