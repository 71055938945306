/* eslint-disable react/state-in-constructor */
import React, { Component } from 'react';
import { HamburgerButton } from 'react-hamburger-button';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {
  Menu, Sidebar,
} from 'semantic-ui-react';
import { Row } from 'react-flexbox-grid';
import NavLogo from '../components/Nav/NavLogo';
import { linkNames } from '../components/Nav/NavList';
import NavSearch from '../components/Nav/NavSearchBar';
import { Tablet } from '../hoc/Layout';
import switchToEnglish from '../hoc/switchToEnglish';
import NavListSecondary from '../components/Nav/NavListSecondary';

export default class NavigationMobile extends Component {
  state={
    open: false,
    visible: false,
  }

  handleSidebarHide = () => this.setState({ visible: false })

  handleClick = () => {
    const { open, visible } = this.state;
    this.setState({
      open: !open,
      visible: !visible,
    });
  }

  render() {
    const { visible, open } = this.state;
    const { en } = this.props;
    return (
      <div>
        <nav>
          <NavLogo />
          <Tablet>
            <NavSearch en={en} />
          </Tablet>
          <div className="nav__hamburger">
            <HamburgerButton
              open={open}
              onClick={this.handleClick}
              width={20}
              height={17}
              strokeWidth={1}
              color="#EF4D08"
              animationDuration={0.5}
            />
          </div>
        </nav>
        <Sidebar
          as={Menu}
          animation="overlay"
          onHide={this.handleSidebarHide}
          vertical
          visible={visible}
          direction="right"
          className="nav__mobileMenu"
        >
          <Menu.Item as="a">
            <AnchorLink href="#aboutUs" className="noStyle uppercase">
              {switchToEnglish(en, linkNames).aboutUs}
            </AnchorLink>
          </Menu.Item>
          <Menu.Item as="a">
            <AnchorLink href="#exp" className="noStyle uppercase">
              {switchToEnglish(en, linkNames).exp}
            </AnchorLink>
          </Menu.Item>
          <Menu.Item as="a">
            <AnchorLink href="#photos" className="noStyle uppercase">
              {switchToEnglish(en, linkNames).photos}
            </AnchorLink>
          </Menu.Item>
          <Menu.Item as="a">
            <AnchorLink href="#yoursRE" className="noStyle uppercase">
              {switchToEnglish(en, linkNames).yoursRE}
            </AnchorLink>
          </Menu.Item>
          <Menu.Item as="a">
            <AnchorLink href="#news" className="noStyle uppercase">
              Aktualności
            </AnchorLink>
          </Menu.Item>
          <Menu.Item as="a" className="nav__mobileMenu--item">
            <AnchorLink href="#contact" className="noStyle uppercase">
              {switchToEnglish(en, linkNames).contact}
            </AnchorLink>
          </Menu.Item>
          <Row center="xs">
            <NavListSecondary {...this.props} />
          </Row>
        </Sidebar>
      </div>
    );
  }
}
