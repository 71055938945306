import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Icon } from 'semantic-ui-react';
import { selectBuilding, unselectBuilding } from '../../store/actions/index';
import searchOptions from '../../data/searchOptions';
import SectionTitle from '../SectionTitle';
import SelectedBuildingAdmin from './SelectedBuildingAdmin';
import SelectedBuildingAcc from './SelectedBuildingAcc';
import SelectedBuildingCard from './SelectedBuildingCard';
import BuildingSearchBar from './BuildingSearchBar';
import { MobileTablet } from '../../hoc/Layout';

class SelectedBuilding extends Component {
  titleText = {
    subititle: 'informacje o Twoim budynku',
  };

  CancelButton = () => (
    <Col xs={0} lg={3}>
      <button
        className="button__light animated fadeIn buildings__cancel buildings__cancel--top"
        type="button"
        onClick={() => this.unselect()}
      >
        <Icon name="cancel" />
      </button>
    </Col>
  )

  CancelButtonMobile = () => (
    <MobileTablet>
      <Row center="xs">
        <Col xs={12}>
          <button
            className="button__light animated fadeIn buildings__cancel--bottom"
            type="button"
            onClick={() => this.unselect()}
          >
            <Icon name="cancel" />
          </button>
        </Col>
      </Row>
    </MobileTablet>
  )

    onChange = (e, data) => {
      this.setState({ selected: data.value }); // eslint-disable-line
    }

    onClick = () => {
      const { select } = this.props;
      const { selected } = this.state;
      const building = searchOptions.filter((e) => e.name === selected);
      select(building[0]);
    }

    unselect() {
      const { unselect } = this.props;
      unselect();
    }

    render() {
      const { building, en } = this.props;
      return (
        <section className="animated fadeIn">
          <Grid>
            <Row between="xl">
              <Col xs={0} lg={3} />
              <Col xs={12} lg={6}>
                <SectionTitle
                  title={`${building.ul}${' '}${building.name}`}
                  subtitle={en ? 'informations about your building' : 'informacje o Twoim budynku'}
                />
              </Col>
              <this.CancelButton
                bottom={false}
              />
            </Row>
            <Row around="xs">
              <Col xs={10} md={8} lg={3} xl={3}>
                <SelectedBuildingAdmin
                  adminPhone={building.adminPhone}
                  adminEmail={building.adminEmail}
                  en={en}
                />
              </Col>
              <Col xs={10} md={8} lg={3} xl={3}>
                <SelectedBuildingAcc
                  accPhone={building.accPhone}
                  accEmail={building.accEmail}
                  accContact={building.accContact}
                  en={en}
                />
              </Col>
              <Col xs={10} md={8} lg={3} xl={3}>
                <SelectedBuildingCard
                  systemType={building.accSystemName}
                  link={building.link}
                  en={en}
                />
              </Col>
            </Row>
            <this.CancelButtonMobile />
            <BuildingSearchBar
              selected
              placeholder="adres budynku"
            />
          </Grid>
        </section>
      );
    }
}

const mapStateToProps = (state) => ({
  building: state.building,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ select: selectBuilding, unselect: unselectBuilding }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedBuilding);
