/* eslint-disable react/prefer-stateless-function */
import React, {
  Component,
} from 'react';
import {
  connect,
} from 'react-redux';
import SelectedBuilding from '../components/Buildings/SelectedBuilding';
import NotSelectedBuilding from '../components/Buildings/NotSelectedBuilding';

class Buildings extends Component {
  render() {
    const {
      building,
    } = this.props;
    return (
      <div
        id="yoursRE"
        className="buildings"
      >
        {building.name === undefined
          ? <NotSelectedBuilding {...this.props} />
          : <SelectedBuilding {...this.props} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  building: state.building,
});

export default connect(mapStateToProps)(Buildings);
