import {
  useWindowSize,
} from '@react-hook/window-size';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import {
  Image,
} from 'semantic-ui-react';
import styled from 'styled-components';
import wspolnik from '../assets/img/wspolnik.png';

export const Comment = () => {
  const [width] = useWindowSize();
  return (
    <Grid>
      <Row
        center="xs"
        middle="xs"
        style={{
          marginTop: 72,
        }}
      >
        <Col
          xlOffset={2}
          xl={2}
          lgOffset={2}
          lg={2}
          mdOffset={1}
          md={3}
        >
          <Avatar
            src={wspolnik}
          />
        </Col>
        <Col
          xl={7}
          lg={7}
          md={8}
        >
          <Row
            middle="xs"
          >
            <MessageTriangleLeft />
            <Message>
              Z przyjemnością sie z Państwem spotkamy,
              aby wyjaśnić zasady współpracy
            </Message>
          </Row>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: 24,
          paddingBottom: width <= 768 ? 48 : 0,
        }}
      >
        <Col
          xlOffset={2}
          xl={9}
          lgOffset={2}
          lg={9}
          style={{
            fontWeight: 700,
          }}
          mdOffset={0}
          md={9}
        >
          Tadeusz Mięsowicz - wspólnik
        </Col>
      </Row>
    </Grid>
  );
};

export const CommentTop = () => (
  <Grid>
    <Row
      center="xs"
      middle="xs"
      style={{
        marginTop: 24,
        marginBottom: 24,
      }}
    >
      <Avatar
        src={wspolnik}
      />
    </Row>
    <Row
      center="xs"
      style={{
        marginBottom: 24,
      }}
    >
      Tadeusz Mięsowicz - wspólnik
    </Row>
    <Row
      middle="xs"
      center="xs"
    >
      <MessageTriangleTop />
    </Row>
    <Row
      middle="xs"
      center="xs"
      style={{
        paddingBottom: 48,
      }}
    >
      <Message>
        Z przyjemnością sie z Państwem spotkamy,
        <br />
        aby wyjaśnić zasady współpracy
      </Message>
    </Row>

  </Grid>
);

const MessageTriangleLeft = styled.div`
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 5px 0;
    border-color: transparent  #EF4D08 transparent transparent ;
`;

const MessageTriangleTop = styled.div`
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent  transparent #EF4D08 transparent ;
`;

const Message = styled.div`
  background: #EF4D08;
  /* border-radius: 5px; */
  padding: 18px;
  font-family: Montserrat;
  color: #FFFFFF;
  line-height: 20px;
  white-space: nowrap;
  font-size: 14px;
  letter-spacing: 0.6px;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  @media only screen and (max-width: 992px){
    font-size: 12px;
  }
`;

const Avatar = styled(Image)`
    box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.42);
    border-radius: 52px;
    background: #EF4D08;
    @media only screen and (max-width: 426px){
      height: 72px;
      width: 72px;
    }
    width: 92px; 
    height: 92px;
    object-fit: cover;
`;
