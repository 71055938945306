/* eslint-disable no-shadow */
/* eslint-disable no-multi-assign */
/* eslint-disable no-unused-expressions */
import _ from 'lodash';

const acc = {
  infinity: {
    accEmail: 'wspolnoty@infinity-as.pl',
    accPhone: '+48 698 517 863',
    accSystemName: 'lokale.net',
    accContact: 'prosimy o kontakt we wtorki i czwartki w godzinach pomiędzy 8.00 a 16.00',
  },
  spectrum: {
    accEmail: 'biuro@arkana.com.pl',
    accPhone: '+48 12 430 10 03',
    accSystemName: 'locus',
    accContact: 'prosimy o kontakt od poniedziałku do czwartku w godzinach pomiędzy 8.00 a 16.00',
  },
};

const admin = {
  jp: {
    adminEmail: 'jerzy.pawlowicz@arkana.com.pl',
    adminPhone: '+48 504 120 181',
  },
  eg: {
    adminEmail: 'techniczne@arkana.com.pl',
    adminPhone: '+48 514 961 940',
  },
};
const lokaleNet = 'https://mmsoft.com.pl/lokale.php?Adm=78';

const locus = (adress) => `https://locus.crspectrum.pl/client/${adress}/login`;

// const accSystem = name => {
//   return
// }

const createBuilding = (name, admin, acc, link, ul) => {
  const building = {};
  building.key = building.value = building.text = building.name = building.title = name;
  switch (link) {
    case undefined:
      building.link = lokaleNet;
      break;
    case 'infinity':
      building.link = lokaleNet;
      break;
    case false:
      building.link = false;
      break;
    default:
      building.link = link;
      break;
  }
  // link === undefined ?  : building.link = link;
  ul === undefined ? building.ul = 'ul.' : building.ul = `${ul}.`;
  return _.merge(building, admin, acc);
};

const batorego7 = createBuilding('Batorego 7', admin.eg, acc.spectrum, locus('batorego7'));
const belinyPrazmowskiego9 = createBuilding('Beliny-Prażmowskiego 9', admin.eg, acc.infinity, 'infinity', 'al');
const bosacka14 = createBuilding('Bosacka 14', admin.jp, acc.infinity);
const bracka6 = createBuilding('Bracka 6', admin.jp, acc.spectrum, locus('bracka6'));
const chodkiewicza10 = createBuilding('Chodkiewicza 10', admin.eg, acc.infinity);
const chodkiewicza13 = createBuilding('Chodkiewicza 13', admin.eg, acc.infinity);
const chodkiewicza21 = createBuilding('Chodkiewicza 21', admin.eg, acc.infinity);
const chodkiewicza6 = createBuilding('Chodkiewicza 6', admin.eg, acc.spectrum, locus('chodkiewicz6'));
const dietla80 = createBuilding('Dietla 80', admin.jp, acc.infinity);
const filarecka6 = createBuilding('Filarecka 6', admin.eg, acc.spectrum, locus('filarecka6'));
const kwartowa = createBuilding('Kwartowa 13', admin.eg, acc.infinity, false);
const gazowa21 = createBuilding('Gazowa 21', admin.jp, acc.infinity, false);
const grodzka18 = createBuilding('Grodzka18', admin.eg, acc.infinity);
const kosciuski44 = createBuilding('Kościuszki 44', admin.eg, acc.spectrum, locus('kosciuszki44'));
const kotlarska4 = createBuilding('Kotlarska 4', admin.jp, acc.spectrum, locus('kotlarska4'));
const krakusa21 = createBuilding('Krakusa 21', admin.eg, acc.spectrum, locus('krakusa21'));
const krasinskiego14 = createBuilding('Krasińkiego 14', admin.eg, acc.infinity, false, 'al');
const lokietka6a = createBuilding('Łokietka 6a', admin.jp, acc.infinity);
const moniuszki51 = createBuilding('Moniuszki 51', admin.eg, acc.infinity, false);
const mostowa12 = createBuilding('Mostowa 12-14', admin.jp, acc.infinity, false);
const np25 = createBuilding('Na Polach 25-35', admin.eg, acc.spectrum, locus('napolach2335'));
const podorska12 = createBuilding('Podgórska 12', admin.jp, acc.infinity);
const retoryka18 = createBuilding('Retoryka 18', admin.jp, acc.spectrum, locus('retoryka18'));
const romanowicza22 = createBuilding('Romanowicza 22', admin.jp, acc.infinity);
const rostworowskiego7 = createBuilding('Rostworowskiego 7  i 9', admin.eg, acc.infinity);
const sebastiana15 = createBuilding('Sebastiana 15', admin.jp, acc.spectrum, locus('sebastiana15'));
const sebastiana4 = createBuilding('Sebastiana 4', admin.jp, acc.spectrum, locus('sebastiana4'));
const smolensk23 = createBuilding('Smoleńsk 23', admin.eg, acc.infinity, false);
const stolarska11 = createBuilding('Stolarska 11', admin.jp, acc.spectrum, locus('stolarska11'));
const syrokomli11 = createBuilding('Syrokomli 11', admin.eg, acc.infinity, false);
const topolowa30 = createBuilding('Topolowa 30', admin.jp, acc.infinity);
const warnenczyka10 = createBuilding('Warneńczyka 10', admin.jp, acc.spectrum, locus('warnenczyka10'));
const zbrojow2 = createBuilding('Zbrojów 2', admin.jp, acc.infinity);
const zyblikiewicza10 = createBuilding('Zyblikiewicza 10', admin.eg, acc.infinity, false);
const zwierzyniecka5 = createBuilding('Zwierzyniecka 5', admin.eg, acc.spectrum, locus('zwierzyniecka5'));

const searchOptions = [
  batorego7,
  belinyPrazmowskiego9,
  bosacka14,
  bracka6,
  chodkiewicza10,
  chodkiewicza13,
  chodkiewicza21,
  chodkiewicza6,
  dietla80,
  filarecka6,
  gazowa21,
  grodzka18,
  kosciuski44,
  kotlarska4,
  krakusa21,
  krasinskiego14,
  kwartowa,
  lokietka6a,
  moniuszki51,
  mostowa12,
  np25,
  podorska12,
  retoryka18,
  romanowicza22,
  rostworowskiego7,
  sebastiana15,
  sebastiana4,
  smolensk23,
  stolarska11,
  syrokomli11,
  topolowa30,
  warnenczyka10,
  zbrojow2,
  zyblikiewicza10,
  zwierzyniecka5,
];

export default searchOptions;

// pawia, piwna, sikorskiego???
