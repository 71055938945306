import React, { useEffect, useState } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import styled from 'styled-components';
import NewsCard from '../components/News/NewsCard';
import SectionTitle from '../components/SectionTitle';
import switchToEnglish from '../hoc/switchToEnglish';
import _firebase from '../_firebase';

const sectionTitleText = {
  en: {
    title: 'News',
    subtitle: 'Contact with us',
  },
  pl: {
    title: 'Aktualności',
    subtitle: 'Bieżące wydarzenia',
  },
};

const News = ({ en }) => {
  const [docs, setDocs] = useState([]);
  useEffect(() => {
    _firebase.firestore().collection('news').onSnapshot((res) => {
      if (!res.empty) {
        const resArr = res.docs.map((value) => ({
          ...value.data(),
          id: value.id,
        }));
        setDocs(resArr);
      }
    });
  }, []);
  return (
    <NewsContainer id="news">
      <div className="contact__space" />
      <SectionTitle
        title={switchToEnglish(en, sectionTitleText).title}
        subtitle={switchToEnglish(en, sectionTitleText).subtitle}
      />
      <Grid>
        {docs.length > 0
          ? (
            <Row around="xs">
              {docs.map((doc) => (
                <NewsCard
                  title={doc.title}
                  desc={doc.desc}
                  timestamp={doc.timestamp}
                  one={docs.length === 1}
                />
              ))}
            </Row>
          ) : (
            <Row center="xs">
              <strong>brak nowych wydarzeń</strong>
            </Row>
          )}
      </Grid>
    </NewsContainer>
  );
};

export default News;

const NewsContainer = styled.section`
  background: white;
`;
