import React from 'react';
import switchToEnglish from '../../hoc/switchToEnglish';

const SelectedBuildingCard = (props) => {
  const { systemType, link, en } = props;

  const cardText = {
    pl: {
      text1: 'system',
      text2: 'księgowy',
    },
    en: {
      text1: 'accountant',
      text2: 'system',
    },
  };

  return (
    <div className="buildings__card">
      <div className="card__title">
        {switchToEnglish(en, cardText).text1}
        <br />
        {switchToEnglish(en, cardText).text2}
        <br />
        {systemType}
      </div>
      <a
        href={`${link}`}
        target="_blank" rel="noreferrer" // eslint-disable-line 
      >
        <button className="card__button" type="button">
          {en ? 'go to' : 'przejdź'}
        </button>
      </a>
    </div>
  );
};

export default SelectedBuildingCard;
