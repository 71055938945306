import React from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import PhotosImage from '../components/Photos/PhotosImage';
import SectionTitle from '../components/SectionTitle';
import switchToEnglish from '../hoc/switchToEnglish';

export const sectionTitleText = {
  pl: {
    title: 'Nieruchomości',
    subtitle: 'Zdjęcia kilku budynków przez nas zarządzanych',
  },
  en: {
    title: 'Photos',
    subtitle: 'Photos of some buildings managed by us',
  },
};

export const buildingFrontText = {
  pl: 'Kraków, elewacja',
  en: 'Kraków, building front',
};
export const buildingText = {
  pl: 'Kraków, budynki',
  en: 'Kraków, buildings',
};

const Photos = ({ en }) => (
  <section className="photos" id="photos">
    <SectionTitle
      title={switchToEnglish(en, sectionTitleText).title}
      subtitle={switchToEnglish(en, sectionTitleText).subtitle}
    />
    <Grid>
      <Row around="xs" className="photos__row">
        <PhotosImage photoName="chodkiewicza" name="ul. Chodkiewicza 6" description={switchToEnglish(en, buildingFrontText)} />
        <PhotosImage photoName="stolarska" name="ul. Stolarska 11" description={switchToEnglish(en, buildingFrontText)} />
        <PhotosImage photoName="bracka" name="ul. Bracka 6" description={switchToEnglish(en, buildingFrontText)} />
      </Row>
      <Row around="xs" className="photos__row">
        <PhotosImage photoName="batorego" name="ul. Batorego 7" description={switchToEnglish(en, buildingFrontText)} />
        <PhotosImage photoName="polach" name="ul. Na Polach 25-35" description={switchToEnglish(en, buildingText)} />
        <PhotosImage photoName="smolensk" name="ul. Smolensk 23" description={switchToEnglish(en, buildingFrontText)} />
      </Row>
    </Grid>
  </section>
);

export default Photos;
