/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { Icon } from 'semantic-ui-react';
import { LINK_FACEBOOK, LINK_INSTAGRAM } from '../data/links';

const Footer = () => (
  <div>
    <footer>
      <article className="footer__menu">
        <Grid>
          <Row around="xl">
            <Col xl={6}>
              <div className="footer__copyright">
                copyright ©
                <span className="bold"> Arkana Nieruchomości Sp. z o. o. 2023 </span>
              </div>
            </Col>
            <Col xl={6}>
              <div className="footer__share">
                <span className="footer__share--links">
                  Social media
                </span>
                <span className="footer__share--links" />
                <a href={LINK_FACEBOOK} target="_blank" rel="noopener noreferrer"><Icon name="facebook" className="icon__hover" /></a>
                <a href={LINK_INSTAGRAM} target="_blank" rel="noopener noreferrer"><Icon name="instagram" /></a>
              </div>
            </Col>
          </Row>
        </Grid>
      </article>
    </footer>
  </div>
);

export default Footer;
