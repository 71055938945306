import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import logo from '../../assets/img/logo.svg';

const NavLogo = () => (
  <div className="nav__logo">
    <AnchorLink href="#top" className="noStyle">
      <img
        src={logo}
        height="28px"
        width="28px"
        alt="log"
      />
    </AnchorLink>
  </div>
);

export default NavLogo;
