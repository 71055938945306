import { useWindowSize } from '@react-hook/window-size';
import React from 'react';
import styled from 'styled-components';
import bg from '../assets/img/waves-sm.svg';
import Slogan from '../components/Slogan';
import AboutUs from '../containers/AboutUs';
import Buildings from '../containers/Buildings';
import Contact from '../containers/Contact';
import Footer from '../containers/Footer';
import NavMobile from '../containers/NavMobile';
import Nav from '../containers/Navigation';
import News from '../containers/News';
import Photos from '../containers/Photos';
import PhotosMobile from '../containers/PhotosMobile';
import Timeline from '../containers/Timeline';
import TimelineMobile from '../containers/TimelineMobile';

import {
  Default, Desktop, Mobile, MobileTablet,
} from '../hoc/Layout';

const Home = ({
  props,
}) => {
  const [width] = useWindowSize();
  return (
    <div className="App">
      {width < 1024
        ? <NavMobile {...props} />
        : <Nav {...props} />}
      <Header
        largeScreen={width > 1440}
      />
      <Slogan />
      <AboutUs {...props} />
      <MobileTablet>
        <TimelineMobile {...props} />
      </MobileTablet>
      <Desktop>
        <Timeline {...props} />
      </Desktop>
      <Mobile>
        <PhotosMobile {...props} />
      </Mobile>
      <Default>
        <Photos {...props} />
      </Default>
      <Buildings {...props} />
      <News {...props} />
      <Contact {...props} />
      <Footer {...props} />
    </div>
  );
};

export default Home;

const Header = styled.header`
  background-image: url(${({ largeScreen }) => (largeScreen ? bg : bg)}); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: absolute;
  right: 0;
  left: 0;
  top: 24px;
  bottom: 700px;
  height: 48em;
  z-index: -1;
  width: 100%;
  @media (max-width: 428px) {
        height:56em;
  }
`;
