import React, { useState } from 'react';
import { Col } from 'react-flexbox-grid';
import { Dimmer, Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import moment from 'moment';
import _firebase from '../../_firebase';
import SectionSubtitle from '../SectionSubtitle';

const convertToMoment = (date) => {
  if (date) {
    return moment(date.toDate()).format('YYYY-MM-DD');
  }
  return null;
};

const NewsCard = ({
  title,
  timestamp,
  desc,
  panel,
  id,
  one,
}) => {
  const [loading, setLoading] = useState(false);
  const deleteNews = async () => {
    try {
      setLoading(true);
      await _firebase.firestore().collection('news').doc(id).delete();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  return (
    <>
      <Dimmer active={loading}>
        <Loader />
      </Dimmer>
      <Col lgOffset={one ? 0 : 1} xlOffset={one ? 0 : 1} xs={10} sm={10} md={4} lg={4} xl={4} className="text-card">
        <div className="contact__order">
          <SectionSubtitle
            subtitle={title}
          />
          <p>
            data utworzenia:
            <span className="bold">
              {' '}
              {convertToMoment(timestamp)}
            </span>
          </p>
        </div>
        <Divider />
        <InfoPar>{desc}</InfoPar>
        { panel
          ? (
            <button
              className="button__light"
              type="button"
              onClick={deleteNews}
              style={{
                marginTop: 12,
              }}
            >
              Usuń
            </button>
          ) : null}
      </Col>
    </>
  );
};

export default NewsCard;

const Divider = styled.div`
    margin: 12px 0;
    border-top: 0.5px solid #F4F4F4;
    width: 100%;
    height: 1px;
`;

const InfoPar = styled.div`
    /* padding-bottom: 12px; */
`;
