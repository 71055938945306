import React from 'react';
import SectionSubtitle from '../SectionSubtitle';
import switchToEnglish from '../../hoc/switchToEnglish';

const SelectedBuildingAcc = (props) => {
  const { accPhone, accEmail, accContact } = props;
  const accText = {
    pl: {
      title: 'Księgowość',
      phone: 'telefon',
      description: `rozliczenia, zaległości, sprawozdania finasowe, ${accContact !== undefined ? accContact : ''}`,
    },
    en: {
      title: 'Accountant',
      phone: 'phone',
      description: `ENrozliczenia, zaległości, sprawozdania finasowe, ${accContact !== undefined ? accContact : ''}`,
    },
  };
  const { en } = props;
  return (
    <div className="text-card">
      <SectionSubtitle subtitle={switchToEnglish(en, accText).title} />
      <p className="bulidings__contact">
        {switchToEnglish(en, accText).phone}
        {' '}
        <span className="bold"><a href={`tel:${accPhone}`}>{accPhone}</a></span>
        <br />
        email:
        {' '}
        <span className="bold"><a href={`mailto:${accEmail}`}>{accEmail}</a></span>
        <br />
      </p>
      <p className="buildings__info">
        {switchToEnglish(en, accText).description}
      </p>
    </div>
  );
};

export default SelectedBuildingAcc;
