import React from 'react';
import ImageGallery from 'react-image-gallery';
import batoregoM from '../assets/img/batorego-m.png';
import batorego from '../assets/img/batorego.jpg';
import brackaM from '../assets/img/bracka -m2.png';
import bracka from '../assets/img/bracka.jpg';
import chodkiewiczaM from '../assets/img/chodkiewicza-m1.png';
import chodkiewicza from '../assets/img/chodkiewicza.jpg';
import npM from '../assets/img/na polach-m.png';
import np from '../assets/img/na polach.jpg';
import smolenskM from '../assets/img/smolensk-m.jpg';
import smolensk from '../assets/img/smolensk.jpg';
import stolarskaM from '../assets/img/stolarska-m.png';
import stolarska from '../assets/img/stolarska.jpg';
import SectionTitle from '../components/SectionTitle';
import switchToEnglish from '../hoc/switchToEnglish';
import { sectionTitleText } from './Photos';

const images = [
  {
    original: batorego,
    thumbnail: batoregoM,
    description: 'ul. Batorego 6, Kraków',
  },
  {
    original: bracka,
    thumbnail: brackaM,
    description: 'ul. Bracka 6, Kraków',
  },
  {
    original: chodkiewicza,
    thumbnail: chodkiewiczaM,
    description: 'ul. Chodkiewicza 6, Kraków',
  },
  {
    original: stolarska,
    thumbnail: stolarskaM,
    description: 'ul. Stolarska 11, Kraków',
  },
  {
    original: np,
    thumbnail: npM,
    description: 'ul. Na Polach 25-25, Kraków',
  },
  {
    original: smolensk,
    thumbnail: smolenskM,
    description: 'ul. Smolensk 23, Kraków',
  },
];

const PhotosMobile = ({ en }) => (
  <section className="photos" id="photos">
    <SectionTitle
      title={switchToEnglish(en, sectionTitleText).title}
      subtitle={switchToEnglish(en, sectionTitleText).subtitle}
    />
    <ImageGallery
      items={images}
      showNav={false}
      showPlayButton={false}
      showFullscreenButton={false}
    />
  </section>
);

export default PhotosMobile;
