import React, { Component } from 'react';
import _ from 'lodash';
import { Icon, Search } from 'semantic-ui-react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectBuilding } from '../../store/actions/index';
import searchOptions from '../../data/searchOptions';

class NavSearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = { showButton: false, selected: null };
  }

  resetComponent = () => this.setState({
    isLoading: false, results: [], value: '', showButton: false,
  })

  handleResultSelect = (e, { result }) => this.setState({ value: result.title });

  onChange = (e, { result }) => {
    this.setState({ selected: result.title });
    this.setState({ showButton: true });
    this.setState({ value: result.title });
  }

  handleSearchChange = (e, { value }) => { // eslint-disable-line
    this.setState({ isLoading: true, value });

    if (value.length < 1) return this.resetComponent();

    const re = new RegExp(_.escapeRegExp(value), 'i');
    const isMatch = (result) => re.test(result.title);

    this.setState({
      isLoading: false,
      results: _.filter(searchOptions, isMatch),
    });
  }

  onClick = () => {
    const { select } = this.props;
    const { selected } = this.state;
    const building = searchOptions.filter((e) => e.name === selected);
    if (building[0] !== undefined) {
      select(building[0]);
    }
  }

  render() {
    const {
      isLoading, value, results, showButton,
    } = this.state;
    const { en } = this.props;
    return (
      <div>
        <Search
          placeholder={en ? ('your building') : ('twój budynek')}
          loading={isLoading}
          onResultSelect={this.onChange}
          onSearchChange={this.handleSearchChange}
          results={results}
          value={value}
          fluid
          {...this.props}
          className="nav__input"
        />
        {showButton ? (
          <AnchorLink href="#yoursRE" className="noStyle">
            <button
              className="button__light animated fadeIn"
              type="button"
              onClick={this.onClick}
            >
              <Icon name="arrow right" />
            </button>
          </AnchorLink>
        ) : null }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  building: state.building,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ select: selectBuilding }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NavSearchBar);
