import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Col, Row } from 'react-flexbox-grid';

const TimelineCardMobile = ({ finish, date, description }) => (
  <div>
    {finish
      ? (
        <Row>
          <Col xs={4}>
            <ScrollAnimation animateIn="pulse">
              <div className="timeline__dot--finish bg__orangeMedium" />
            </ScrollAnimation>
          </Col>
          <Col xs={6}>
            <ScrollAnimation animateIn="slideInRight">
              <div className="timeline__title">
                {date}
              </div>
              <div className="timeline__pointer--right" />
              <div>
                <p>
                  {description}
                </p>
              </div>
            </ScrollAnimation>
          </Col>
        </Row>
      )
      : (
        <Row>
          <Col xs={4}>
            <div className="timeline__dot bg__orangeMedium" />
          </Col>
          <Col xs={6}>
            <ScrollAnimation animateIn="slideInRight">
              <div className="timeline__title">
                {date}
              </div>
              <div className="timeline__pointer--right" />
              <div>
                <p>
                  {description}
                </p>
              </div>
            </ScrollAnimation>
          </Col>
        </Row>
      )}
  </div>
);

export default TimelineCardMobile;
