import React, { Component } from 'react';
import { Col } from 'react-flexbox-grid';
import {
  Image, Dimmer,
} from 'semantic-ui-react';
import ScrollAnimation from 'react-animate-on-scroll';
import chodkiewiczaM from '../../assets/img/chodkiewicza-m1.png';
import chodkiewicza from '../../assets/img/chodkiewicza.jpg';
import stolarskaM from '../../assets/img/stolarska-m.png';
import stolarska from '../../assets/img/stolarska.jpg';
import brackaM from '../../assets/img/bracka -m2.png';
import bracka from '../../assets/img/bracka.jpg';
import batoregoM from '../../assets/img/batorego-m.png';
import batorego from '../../assets/img/batorego.jpg';
import smolenskM from '../../assets/img/smolensk-m.jpg';
import smolensk from '../../assets/img/smolensk.jpg';
import npM from '../../assets/img/na polach-m.png';
import np from '../../assets/img/na polach.jpg';

const photosM = [chodkiewiczaM, stolarskaM, brackaM, batoregoM, smolenskM, npM];
const photos = [chodkiewicza, stolarska, bracka, batorego, smolensk, np];

const nameCheck = (photoName, photosArr) => {
  const c = photosArr.filter((ele) => ele.includes(photoName));
  return c[0];
};

export default class PhotosImage extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state= {}

  onClick = (event) => {
    event.preventDefault();

    this.setState({ active: true });
  }

  onClose = (event) => {
    event.preventDefault();
    this.setState({ active: false });
  }

  render() {
    const { active } = this.state;
    const { photoName, name, description } = this.props;

    return (
      <Col xs={4}>
        <Dimmer active={active} onClickOutside={() => this.setState({ active: false })} page>
          <a // eslint-disable-line
            href="#"
            className="close"
            onClick={(e) => this.onClose(e)}
          />
          <div className="subtitle__line" />
          <Image src={nameCheck(photoName, photos)} size="large" centered />
        </Dimmer>
        <ScrollAnimation animateOnce animateIn="fadeIn" offset={50}>
          <div className="subtitle__line" />
          <div>
            <Image src={nameCheck(photoName, photosM)} centered as="a" href="#" onClick={this.onClick} />
            <div className="photo__title">
              {name}
            </div>
            <p>{description}</p>
          </div>
        </ScrollAnimation>
      </Col>
    );
  }
}
