import React from 'react';

const SectionTitle = ({
  title,
  subtitle,
  noTop,
}) => (
  <div className={`sectionTitle ${noTop ? 'no-top' : ''}`}>
    <span className="sectionTitle__line">{title}</span>
    <div className="sectionTitle__discription">{subtitle}</div>
  </div>
);

export default SectionTitle;
