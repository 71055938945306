import React from 'react';
import { useWindowSize } from '@react-hook/window-size';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import switchToEnglish from '../../hoc/switchToEnglish';

const ul = {
  listStyle: 'none !important',
  display: 'flex',
  paddingInlineStart: '0',
  flexGrow: '1',
  justifyContent: 'flex-end',
};

export const linkNames = {
  en: {
    aboutUs: 'about us',
    exp: 'experience',
    photos: 'photos',
    yoursRE: 'your building',
    contact: 'contact',
  },
  pl: {
    aboutUs: 'o nas',
    exp: 'doświadczenie',
    photos: 'zdjęcia',
    yoursRE: 'twoja nieruchomość',
    contact: 'kontakt',
  },
};

const NavList = ({ en }) => {
  const [width] = useWindowSize();
  return (
    <Scrollspy
      items={['aboutUs', 'exp', 'photos', 'yoursRE', 'news', 'contact']}
      style={ul}
      currentClassName="nav__list-item--underline"
      offset={-70}
    >
      <li className="nav__list-item">
        <AnchorLink href="#aboutUs" className="noStyle">
          {switchToEnglish(en, linkNames).aboutUs}
        </AnchorLink>
      </li>
      <li className="nav__list-item">
        <AnchorLink href="#exp" className="noStyle">
          {switchToEnglish(en, linkNames).exp}
        </AnchorLink>
      </li>
      {width >= 1400
        ? (
          <li className="nav__list-item">
            <AnchorLink href="#photos" className="noStyle">
              {switchToEnglish(en, linkNames).photos}
            </AnchorLink>
          </li>
        ) : null }
      <li className="nav__list-item">
        <AnchorLink href="#yoursRE" className="noStyle">
          {switchToEnglish(en, linkNames).yoursRE}
        </AnchorLink>
      </li>
      <li className="nav__list-item">
        <AnchorLink href="#news" className="noStyle">
          Actualności
        </AnchorLink>
      </li>
      <li className="nav__list-item">
        <AnchorLink href="#contact" className="noStyle">
          {switchToEnglish(en, linkNames).contact}
        </AnchorLink>
      </li>
    </Scrollspy>
  );
};
export default NavList;
