import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';
import { Search } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { selectBuilding } from '../../store/actions/index';
import searchOptions from '../../data/searchOptions';

class BuildingSearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: null };
  }

    onClick = () => {
      const { select } = this.props;
      const { selected } = this.state;
      const building = searchOptions.filter((e) => e.name === selected);
      if (building[0] !== undefined) {
        select(building[0]);
      }
    }

    resetComponent = () => this.setState({
      isLoading: false, results: [], value: '',
    })

    handleResultSelect = (e, { result }) => this.setState({ value: result.title });

    onChange = (e, { result }) => {
      this.setState({ selected: result.title });
      this.setState({ value: result.title });
    }

    handleSearchChange = (e, { value }) => { // eslint-disable-line
      this.setState({ isLoading: true, value });

      if (value.length < 1) return this.resetComponent();

      const re = new RegExp(_.escapeRegExp(value), 'i');
      const isMatch = (result) => re.test(result.title);

      this.setState({
        isLoading: false,
        results: _.filter(searchOptions, isMatch),
      });
    }

    handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        this.onClick();
      }
    };

    render() {
      const { selected, placeholder } = this.props;
      const { isLoading, value, results } = this.state;
      return (
        <div>
          {selected
            ? (
              <Row centered around="xs">
                <Col>
                  <div className="buildings__seperatorselected" />
                </Col>
              </Row>
            ) : null }
          <Row className={selected ? 'buildings__nextInput' : null} center="xs">
            <Col xs={12} md={8}>
              <Search
                placeholder={placeholder}
                loading={isLoading}
                onResultSelect={this.onChange}
                onSearchChange={this.handleSearchChange}
                results={results}
                value={value}
                fluid
                className="nav__input buildings__nextInput"
                onKeyDown={this.handleKeyDown}
                {...this.props}
              />
            </Col>
            <Col xs={12}>
              <AnchorLink href="#yoursRE" className="noStyle">
                <button
                  className="button__dark--left"
                  onClick={this.onClick}
                  type="button"

                >
                  Przejdź
                </button>
              </AnchorLink>
            </Col>
          </Row>
          {selected ? null
            : (
              <Row around="xs">
                <Col>
                  <div className="buildings__seperator" />
                </Col>
              </Row>
            )}
        </div>
      );
    }
}

const mapStateToProps = (state) => ({
  building: state.building,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ select: selectBuilding }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildingSearchBar);
