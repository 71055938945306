import React from 'react';
import SectionSubtitle from '../SectionSubtitle';
import switchToEnglish from '../../hoc/switchToEnglish';

const SelectedBuildingAdmin = (props) => {
  const adminText = {
    pl: {
      phone: 'telefon',
      description: 'sprawy techniczne i biezące, awarie. Posimy o kontakt od poniedziałku do piątku w godzinach pracy 8.00-16.00.',
    },
    en: {
      phone: 'phone',
      description: 'ENsprawy techniczne i biezące, awarie. Posimy o kontakt w godzinach pracy 8.00-16.00 .',
    },

  };

  const { adminPhone, adminEmail, en } = props;
  return (
    <div className="text-card">
      <SectionSubtitle subtitle="Administrator" />
      <p className="bulidings__contact">
        {switchToEnglish(en, adminText).phone}
        {' '}
        <span className="bold"><a href={`tel:${adminPhone}`}>{adminPhone}</a></span>
        <br />
        email:
        {' '}
        <span className="bold"><a href={`mailto:${adminEmail}`}>{adminEmail}</a></span>
        <br />
      </p>
      <div>
        <p className="buildings__info">
          {switchToEnglish(en, adminText).description}
        </p>
      </div>
    </div>
  );
};

export default SelectedBuildingAdmin;
