import React from 'react';
import { Col } from 'react-flexbox-grid';
import ReactHtmlParser from 'react-html-parser';

const MainCard = ({ title, buttonText }) => (
  <Col xs={10} sm={8} md={6} lg={5} xl={4}>
    <div className="aboutUs__why">
      <div className="card__main">
        <div className="card__title">
          {ReactHtmlParser(title)}
        </div>
        <a
          href="https://firebasestorage.googleapis.com/v0/b/arkana-n.appspot.com/o/folder.pdf?alt=media&token=b1254711-63e5-4bb7-a3c3-6ccdb99d011c"
          target="_blank"
          rel="noreferrer"
        >
          <button
            className="card__button"
            type="button"
          >
            {buttonText}
          </button>
        </a>
      </div>
    </div>
  </Col>
);

export default MainCard;
