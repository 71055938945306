import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NavSearchBar from '../components/Nav/NavSearchBar';
import NavList from '../components/Nav/NavList';
import NavLogo from '../components/Nav/NavLogo';
import NavListSecondary from '../components/Nav/NavListSecondary';
import { unselectBuilding } from '../store/actions/index';

class Navigation extends Component {
  unselect(e) {
    const { unselect } = this.props;
    e.preventDefault();
    unselect();
  }

  render() {
    const { building, en } = this.props;
    return (
      <nav>
        <NavLogo />
        <NavList {...this.props} />
        {building.name === undefined
          ? <NavSearchBar en={en} />
          : (
            <div className="nav__selected">
              <div>
                {building.ul}
                {' '}
                {building.name}
                {' '}
              </div>
              <a /* eslint-disable-line */
                onClick={(e) => this.unselect(e)}
                className="nav__selected--cancel"
              >
                X
              </a>
            </div>
          )}
        <NavListSecondary {...this.props} />
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  building: state.building,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ unselect: unselectBuilding }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
