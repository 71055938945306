import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import ReactHtmlParser from 'react-html-parser';
import SectionTitle from '../components/SectionTitle';
import TimlineCard from '../components/Timeline/TimelineCard';
import switchToEnglish from '../hoc/switchToEnglish';

const joinUsTextPL = 'DOŁĄCZ<br/>DO<br/>NAS';
const joinUsTextEN = 'JOIN<br/>US';

export const sectionTitleText = {
  pl: {
    title: 'Doświadczenie',
    subtitle: 'historia Arkana Nieruchomości',
  },
  en: {
    title: 'Experience',
    subtitle: 'history of Arkana Nieruchomości',
  },
};

export const card1Text = {
  pl:
      {
        date: '1995',
        description: 'Elżbieta Mięsowicz zakłada firmę Administracja Domów ARKANA. Na początku działalność prowadzona jest osobiście w siedzibie przy ul. Starowiślnej w Krakowie.',
      },
  en:
      {
        date: '1995',
        description: 'ENElżbieta Mięsowicz zakłada firmę Administracja Domów ARKANA. Na początku działalność prowadzona jest osobiście w siedzibie przy ul. Starowiślnej w Krakowie.',
      },
};
export const card2Text = {
  pl: {
    date: '2000',
    description: 'Firma zatrudnia już 4 pracowników i zarządza 23 nieruchomościami. Siedziba firmy mieści się przy ul. Piłsudskiego w Krakowie.',
  },
  en: {
    date: '2000',
    description: 'ENFirma zatrudnia już 4 pracowników i zarządza 23 nieruchomościami. Siedziba firmy mieści się przy ul. Piłsudskiego w Krakowie.',
  },
};
export const card3Text = {
  pl: {
    date: '2012',
    description: 'Zaczynamy przekształcenia. Firma przeprowadza skutecznie i sprawnie pierwszy projekt przekształcenia osiedla we wspólnotę mieszkaniową.',
  },
  en: {
    date: '2012',
    description: 'ENZaczynamy przekształcenia. Firma przeprowadza skutecznie i sprawnie pierwszy projekt przekształcenia osiedla we wspólnotę mieszkaniową.',
  },
};
export const card4Text = {
  pl: {
    date: '2013',
    description: 'Firma zmienia siedzibę, przenosi się do własnego lokal przy ul. Chodkiewicza 6/15 w Krakowie. Zarządzamy ponad 50 budynkami.',
  },
  en: {
    date: '2013',
    description: 'ENFirma zmienia siedzibę, przenosi się do własnego lokal przy ul. Chodkiewicza 6/15 w Krakowie. Zarządzamy ponad 50 budynkami.(w tym osiedlami)',
  },
};

const Line = () => (
  <Row around="xl">
    <Col xs={12} className="timeline__lineHeight" />
  </Row>
);

const Timeline = ({ en }) => (
  <section className="timeline" id="exp">
    <SectionTitle
      title={switchToEnglish(en, sectionTitleText).title}
      subtitle={switchToEnglish(en, sectionTitleText).subtitle}
    />
    <div className="timeline__mainLine" />
    <Grid>
      <TimlineCard
        date="1995"
        description={switchToEnglish(en, card1Text).description}
      />
      <Line />
      <TimlineCard
        date="2000"
        description={switchToEnglish(en, card2Text).description}
        right
      />
      <Line />
      <TimlineCard
        date="2012"
        description={switchToEnglish(en, card3Text).description}
      />
      <Line />
      <TimlineCard
        date="2013"
        description={switchToEnglish(en, card4Text).description}
        right
      />
      <Line />
      <Row>
        <Col xl={4} />
        <Col xl={4} className="timeline__container">
          <div className="timeline__cirle--text bg__orangeDark">
            {en ? ReactHtmlParser(joinUsTextEN) : ReactHtmlParser(joinUsTextPL)}
          </div>
        </Col>
        <Col xl={4} />
      </Row>
    </Grid>
  </section>
);

export default Timeline;
