import React from 'react';

const SectionSubtitle = ({
  subtitle,
}) => (
  <div>
    <h3 className="subtitle">
      {subtitle}
    </h3>
    <div className="subtitle__line" />
  </div>
);

export default SectionSubtitle;
