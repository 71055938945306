import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Col, Row } from 'react-flexbox-grid';

const TimelineCard = ({ right, date, description }) => (
  <div>
    {right
      ? (
        <Row>
          <Col xs={4} />
          <Col xs={4} className="timeline__container">
            <ScrollAnimation animateIn="pulse">
              <div className="timeline__dot bg__orangeMedium" />
            </ScrollAnimation>
          </Col>
          <Col xs={4}>
            <ScrollAnimation animateIn="slideInRight">
              <div className="timeline__title">
                {date}
              </div>
              <div className="timeline__pointer--right" />
              <div>
                <p>
                  {description}
                </p>
              </div>
            </ScrollAnimation>
          </Col>
        </Row>
      )
      : (
        <Row>
          <Col xs={4}>
            <ScrollAnimation animateIn="slideInLeft">
              <div className="timeline__title--right">
                {date}
              </div>
              <div className="timeline__pointer pullRight" />
              <div className="pullRight timeline__text">
                <p>
                  {description}
                </p>
              </div>
            </ScrollAnimation>
          </Col>
          <Col xs={4} className="timeline__container">
            <ScrollAnimation animateIn="pulse">
              <div className="timeline__dot bg__orangeMedium" />
            </ScrollAnimation>
          </Col>
          <Col xs={4} />
        </Row>
      )}
  </div>
);

export default TimelineCard;
