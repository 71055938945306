import * as actionTypes from './actionTypes';

export function selectBuilding(buildings) {
  return {
    type: actionTypes.SELECT_BUILDING,
    payload: buildings,
  };
}
// test
export function unselectBuilding() {
  return {
    type: actionTypes.UNSELECT_BUILDING,
  };
}

export function selectEnglish() {
  return {
    type: actionTypes.SELECT_POLISH,
  };
}

export function selectPolish() {
  return {
    type: actionTypes.SELECT_POLISH,
  };
}
