import React from 'react';
import { Col } from 'react-flexbox-grid';
import ReactHtmlParser from 'react-html-parser';
import SectionSubtitle from './SectionSubtitle';

const TextCard = ({
  title, description, right, children,
}) => (
  <Col xs={10} sm={8} md={6} lg={5} xl={4}>
    <div className={right === true ? 'text-card text-card__right' : 'text-card'}>
      {children}
      <SectionSubtitle subtitle={ReactHtmlParser(title)} />
      <p>
        {ReactHtmlParser(description)}
      </p>
    </div>
  </Col>
);

export default TextCard;
