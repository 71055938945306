/* eslint-disable react/no-unused-state */
import React from 'react';
import { connect } from 'react-redux';
import './App.scss';
import Router from './routes/Router';

const App = () => (
  <Router />
);

const mapStateToProps = (state) => ({
  en: state.english,
  pl: state.polish,
});

export default connect(mapStateToProps)(App);
