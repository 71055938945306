import {
  useWindowSize,
} from '@react-hook/window-size';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import styled from 'styled-components';
import space from '../assets/img/space.jpg';
import { Comment, CommentTop } from '../components/Comments';
import MainCard from '../components/MainCard';
import SectionTitle from '../components/SectionTitle';
import TextCard from '../components/TextCard';
import { Default, Mobile } from '../hoc/Layout';
import switchToEnglish from '../hoc/switchToEnglish';

const textCard1 = {
  pl: {
    title: 'Zarządzanie <br/> nieruchomościami',
    description: '<div><span class="bold">Arkana Nieruchomości Sp. z o.o. - od ponad 20 lat</span> zarządzamy budynkami mieszkalnymi i komercyjnymi. Jesteśmy jedną z pierwszych firm powstałych w latach dziewięćdziesiątych XX wieku zajmujących się opieką nad nieruchomościami. Zdobyliśmy ogromne doświadczenie, co owocuje skutecznością w aktualnej pracy. <br/> Z przyjemnością podzielimy się z Państwem naszą wiedzą i doświadczeniem.</div>',
    descriptionMobile: '<div><span class="bold">Od ponad 20 lat</span> zarządzamy budynkami mieszkalnymi i komercyjnymi. Jesteśmy jedną z pierwszych firm powstałych w latach dziewięćdziesiątych XX wieku zajmujących się opieką nad nieruchomościami. Zdobyliśmy ogromne doświadczenie, co owocuje skutecznością w aktualnej pracy. <br/> Z przyjemnością podzielimy się z Państwem naszą wiedzą i doświadczeniem.</div>',
  },
  en: {
    title: 'Real esstate <br/> managment',
    description: 'Zarządzamy od ponad 20 lat budynkami mieszkalnymi i komercyjnymi <span class="bold">od ponad 20 lat</span>. W tym czasie opiekowaliśmy się wieloma nieruchomościami i jesteśmy jedną z wiodących firm świadczących tego typu usługi w Krakowie. Z całych sił staramy się, aby dzięki naszej pracy Klienci oszczędzali czas i wysiłek związany z funkcjonowaniem ich nieruchomości. Z chęcią podzielimy się swoim doświadczeniem i wiedzą.Z przyjemnością przedstawimy Państwu naszą ofertę. Zakres obowiązków i wynagrodzenie ustalamy indywidualnie.<br/>Prosimy o <a href="#contact"><span class="bold">kontakt</span></a>, serdecznie zapraszamy do współpracy.</div>',
    descriptionMobile: 'Zarządzamy od ponad 20 lat budynka  mi mieszkalnymi i komercyjnymi <span class="bold">od ponad 20 lat</span>. <br/>W tym czasie opiekowaliśmy się wieloma nieruchomościami i jesteśmy jedną z wiodących firm świadczących tego typu usługi w Krakowie.</div>',
  },
};

const textCard2 = {
  pl: {
    title: 'Pomagamy z reklamacją u developera',
    description: 'Twój developer oddał budynek z wadami? Nic się nie dzieje w tej sprawie? Zapraszamy do nas, zajmiemy się Twoim budynkiem i pomożemy to zmienić. <span class="bold">Ocenimy sytuację, nakreślimy plan działania. Następnie podejmiemy negocjacje i w razie konieczności skoordynujemy kompetentnych prawników. </span> Co miesiąc Twoja wspólnota otrzyma raport z podjętych czynności. <span class="bold">My działamy!</span>',
    descriptionMobile: 'Twój developer oddał budynek z wadami? Nic się nie dzieje w tej sprawie? Zapraszamy do nas, zajmiemy się Twoim budynkiem i pomożemy to zmienić. <span class="bold">Ocenimy sytuację, nakreślimy plan działania. Następnie podejmiemy negocjacje i w razie konieczności skoordynujemy kompetentnych prawników. </span> Co miesiąc Twoja wspólnota otrzyma raport z podjętych czynności<span class="bold"> My działamy! </span>',
  },
  en: {
    title: 'Block Transformers :p<br />spółdzielczych we wspólnoty',
    description: 'Posiadamy wiedzę, know-how i doświadczenie w przekształcaniu bloków spółdzielczych we wspólnoty mieszkaniowe. Z chęcią <span class="bold">pomożemy Państwu zorganizować cały proces</span>, jak również obejmiemy zarząd nowo powstałą wspólnotą mieszkaniową. Jeżeli chcieliby Państwo mieć większy wpływ na zarządzanie budynkiem, niż się to dzieje w ramach spółdzielni, to wspólnota mieszkaniowa jest właściwym rozwiązaniem. Serdecznie zapraszamy do współpracy - prosimy o kontakt celem ustalenia warunków. Z nami cały proces przebiegnie sprawnie i profesjonalnie.',
    descriptionMobile: 'Posiadamy wiedzę, know-how i doświadczenie w przekształcaniu bloków spółdzielczych we wspólnoty mieszkaniowe. Z chęcią <span class="bold">pomożemy Państwu zorganizować cały proces</span>, jak również obejmiemy zarząd nowo powstałą wspólnotą mieszkaniową.',
  },
};

const textCard3 = {
  pl: {
    title: 'Czym <br/> zarządzamy?',
    description: 'Organizujemy funkcjonowanie <span class="bold">wspólnot mieszkaniowych</span>. Zajmujemy się <span class="bold">budynkami mieszkalnymi</span> będącymi przedmiotem własności i współwłasności. Administrujemy <span class="bold">nieruchomości komercyjne</span>. Zarządzamy też indywidualnymi <span class="bold">lokalami.</span> <br/> Serdecznie zapraszamy do współpracy, prosimy o  kontakt.',
  },
  en: {
    title: 'Areas of <br/> practice',
    description: 'Organizujemy funkcjonowanie <span class="bold">wspólnot mieszkaniowych</span>. Zajmujemy się <span class="bold">budynkami mieszkalnymi</span> będącymi przedmiotem własności i współwłasności. Administrujemy <span class="bold">nieruchomości komercyjne</span>. Zarządzamy też indywidualnymi <span class="bold">lokalami.</span> <br/> Serdecznie zapraszamy do współpracy, prosimy o  kontakt.',
  },
};

const mainCard = {
  pl: {
    title: 'POBIERZ<br />NASZ<br />FOLDER',
    buttonText: 'POBIERZ',
  },
  en: {
    title: 'DOWNLOAD<br />OUR<br />FLAYER',
    buttonText: 'DOWNLOAD',
  },
};

const AboutUs = ({ en }) => {
  const [width, height] = useWindowSize();
  return (
    <div>
      <section
        className="about-us no-top"
        id="aboutUs"
        noTop
      >
        <SectionTitle title="O NAS" subtitle="poznaj nas lepiej" />
        <Grid fluid>
          <Row around="xs">
            <Col xs={0} sm={0} md={0} lg={1} xl={2} />
            <Mobile>
              <TextCard
                title={switchToEnglish(en, textCard1).title}
                description={switchToEnglish(en, textCard1).descriptionMobile}
                right
              />
              <TextCard
                title={switchToEnglish(en, textCard2).title}
                description={switchToEnglish(en, textCard2).descriptionMobile}
              />
            </Mobile>
            <Default>
              <TextCard
                title={switchToEnglish(en, textCard1).title}
                description={switchToEnglish(en, textCard1).description}
                right
              />
              <TextCard
                title={switchToEnglish(en, textCard2).title}
                description={switchToEnglish(en, textCard2).description}
              />
            </Default>
            <Col xs={0} m={0} md={0} lg={1} xl={2} />
          </Row>
          <Row around="xs" className="about-us__row">
            <Col xs={0} sm={0} md={0} lg={1} xl={2} />
            <TextCard
              title={switchToEnglish(en, textCard3).title}
              description={switchToEnglish(en, textCard3).description}
              right
            />
            <MainCard
              title={switchToEnglish(en, mainCard).title}
              buttonText={switchToEnglish(en, mainCard).buttonText}
            />
            <Col xs={0} sm={0} md={0} lg={1} xl={2} />
          </Row>
          {width >= 768
            ? <Comment />
            : <CommentTop /> }
        </Grid>
      </section>
      <Space />
    </div>
  );
};

export default AboutUs;

const Space = styled.section`
    width: 100%;
    height: 200px;
    background-image: url(${space});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media (max-width: 768px) {
        height: 80px;
    }
`;
