import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import BuildingSearchBar from './BuildingSearchBar';
import SectionTitle from '../SectionTitle';
import switchToEnglish from '../../hoc/switchToEnglish';

const descriptionText = {
  pl: {
    text1: 'po wybraniu wyświetlą się',
    text2: 'informacje kontaktowe',
    text3: 'administratora budynku',
    text4: 'link do systemów księgowych',
    text5: 'lub',
  },
  en: {
    text1: 'if you select the buildng',
    text2: 'contact info will be reveled according to',
    text3: "building's administrator",
    text4: 'link to login into accountant systems',
    text5: 'or',
  },
};

const sectionTitleText = {
  pl: {
    title: 'Twoja nieruchomość',
    subtitle: 'informacje o Twoim budynku',
  },
  en: {
    title: 'Your building',
    subtitle: 'informations about your building',
  },
};

const NotSelectedBuilding = ({ en }) => (
  <section className="animated fadeIn">
    <SectionTitle
      title={switchToEnglish(en, sectionTitleText).title}
      subtitle={switchToEnglish(en, sectionTitleText).subtitle}
    />
    <Grid>
      <BuildingSearchBar placeholder="wpisz adres nieruchomości" />
      <Row around="xs">
        <Col center="x" xs={12} xl={5}>
          <p className="buildings__discription">
            {switchToEnglish(en, descriptionText).text1}
            <br />
            {switchToEnglish(en, descriptionText).text2}
            <span className="bold">
              {' '}
              {switchToEnglish(en, descriptionText).text3}
            </span>
            ,
            <br />
            {switchToEnglish(en, descriptionText).text4}
            <span className="bold"> Locus </span>
            {switchToEnglish(en, descriptionText).text5}
            <span className="bold"> Lokale.net</span>
          </p>
        </Col>
      </Row>
    </Grid>
  </section>
);

export default NotSelectedBuilding;
