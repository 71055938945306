import React, { useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import {
  Icon, Input, TextArea, Dimmer, Loader,
} from 'semantic-ui-react';
import styled from 'styled-components';
import firebase from 'firebase';

import _firebase from '../../_firebase';
import logo from '../../assets/img/logo.svg';
import SectionTitle from '../SectionTitle';
import { Container, Wrapper } from './Login';
import NewsCard from '../News/NewsCard';

const Panel = () => {
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [fbLink, setFbLink] = useState('');
  const [docs, setDocs] = useState([]);
  const [newsVisible, setNewsVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const addNews = async () => {
    if (title && desc) {
      try {
        setLoading(true);
        await _firebase.firestore().collection('news').add({
          title,
          desc,
          timestamp: firebase.firestore.Timestamp.now(),
          fbLink: fbLink ?? null,
        });
        setLoading(false);
        setTitle('');
        setDesc('');
        setFbLink('');
      } catch (error) {
        setLoading(false);
        setTitle('');
        setDesc('');
        setFbLink('');
      }
    }
  };

  useEffect(() => {
    _firebase.firestore().collection('news').onSnapshot((res) => {
      if (!res.empty) {
        const resArr = res.docs.map((value) => ({
          ...value.data(),
          id: value.id,
        }));
        setDocs(resArr);
      }
    });
  }, []);

  if (newsVisible) {
    return (
      <>
        <Grid>
          <Wrapper
            style={{
              marginTop: 48,
            }}
          >
            <img
              src={logo}
              height="48px"
              width="48px"
              alt="log"
            />
          </Wrapper>
          <Wrapper>
            <Container>
              <SectionTitle
                title="Wydarzenia"
                subtitle="Usuń zbędne aktualności"
              />
            </Container>
          </Wrapper>
          <Wrapper style={{
            marginTop: 24,
          }}
          >
            <NewsContainer>
              <Row between="xs">
                {docs.map((doc) => (
                  <NewsCard
                    title={doc.title}
                    desc={doc.desc}
                    timestamp={doc.timestamp}
                    panel
                    id={doc.id}
                  />
                ))}
              </Row>
            </NewsContainer>
          </Wrapper>
          <Wrapper>
            <Container>
              <Row
                center="xs"
                style={{
                  marginTop: 48,
                  marginBottom: 88,
                }}
              >
                <Col
                  xs={4}
                >
                  <button
                    className="button__dark"
                    type="button"
                    onClick={() => {
                      setNewsVisible(false);
                    }}
                  >
                    Wróć
                  </button>
                </Col>
              </Row>
            </Container>
          </Wrapper>
        </Grid>
      </>
    );
  }
  return (
    <>
      <Dimmer active={loading}>
        <Loader />
      </Dimmer>
      <Grid>
        <section id="contact" className="contact">
          <Wrapper>
            <img
              src={logo}
              height="48px"
              width="48px"
              alt="log"
            />
          </Wrapper>
          <Wrapper>
            <Container>
              <SectionTitle
                title="Edytuj aktualności"
                subtitle="Dodaj nową lub usuń stare. Wyświetlają się max 4."
              />
              <TextAreaStyled
                placeholder="Tytuł aktualności"
                rows={3}
                value={title}
                onChange={(event) => setTitle(event.target.value)}
              />
              <TextAreaStyled
                placeholder="Treść"
                rows={4}
                onChange={(event) => setDesc(event.target.value)}
                style={{
                  marginTop: 12,
                }}
                value={desc}
              />

              <Input
                type="text"
                placeholder="Link do wydarzenia na Facebooku"
                fluid
                value={fbLink}
                onChange={(event) => setFbLink(event.target.value)}
                style={{
                  marginTop: 12,
                }}
                label={{
                  content: <Icon
                    name="facebook"
                    style={{
                      color: '#2c2c2c',
                    }}
                  />,
                }}
              />

            </Container>
          </Wrapper>
          <Wrapper>
            <Container>
              <Row
                end="xs"
                style={{
                  marginTop: 24,
                }}
              >
                <Col
                  xs={4}
                >
                  <button className="button__light" type="button" onClick={addNews}>
                    Dodaj
                  </button>
                </Col>
              </Row>
            </Container>
          </Wrapper>
          <Wrapper>
            <Container>
              <Row
                between="xs"
                style={{
                  marginTop: 48,
                  marginBottom: 48,
                }}
              >
                <Col
                  xs={4}
                >
                  <button className="button__dark" type="button" onClick={() => setNewsVisible(true)}>
                    Wydarzenia
                  </button>
                </Col>
                <Col
                  xs={4}
                >
                  <button className="button__dark" type="button" onClick={() => _firebase.auth().signOut()}>
                    wyloguj się
                  </button>
                </Col>
              </Row>
            </Container>
          </Wrapper>
        </section>
      </Grid>
    </>
  );
};

export default Panel;

const TextAreaStyled = styled(TextArea)`
  width: 100%;
  border-color: #f1f1f1;
  padding: 12px;
  font-family: "Lato";
  outline: none;
  ::placeholder{
    color: #EF4D08 !important;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
  }
  :focus{
    border-color: #EF4D08 !important;
  }
`;

export const NewsContainer = styled.div`
  width: 992px;
`;
